import React from "react";
import Paper from "@mui/material/Paper";
import { Item } from "./Item";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation, i18n } from "react-i18next";

import {
  Box,
  TableContainer,
  TableCell,
  TableRow,
  Button,
  TableFooter,
  Collapse,
  Table,
  TableBody,
} from "@mui/material";

export default function SbbComponent(props) {
  const { classes, rows, progress, pairValueColor } = props;
  const [openMessLocation, setOpenMessLocation] = React.useState(false);
  const { t, i18n } = useTranslation();

  return (
    <Item style={{ height: "fit-content" }}>
      <Box className={classes.boxIconDatabase}>
        {/* <span
          style={{
            height: "25px",
            width: "250px",
            fontFamily: "fantasy",
            fontSize: "1.6rem",
            color: "#C70039",
          }}
        >
          {t("schweizerBahnbetreiber")}
        </span> */}
        <img
          alt="sbbIcon"
          src="./sbbIcon.jpg"
          style={{ height: "25px", width: "250px", marginTop: "10px" }}
        ></img>
        <form style={{ marginTop: "10px" }}></form>
        <CircularProgress
          color="error"
          sx={
            progress ? { display: "flex", margin: "auto" } : { display: "none" }
          }
        />

        <img
          alt="datenbank"
          src="./datenbank-foto.jpg"
          className={classes.photoDatabase}
        ></img>
      </Box>

      <Box className={classes.boxTable}>
        <Paper className={classes.titleInfo}>{t("DiagnoseDaten")}</Paper>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              {rows.map((row) => {
                if (row.name === "LagerPosition") {
                  if (row.value !== undefined && row.value !== null) {
                    return (
                      <TableRow
                        key={row.name}
                        style={
                          openMessLocation || progress
                            ? { display: "none" }
                            : null
                        }
                      >
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>

                        <TableCell
                          style={{ width: 160, fontSize: "small" }}
                          align="right"
                        >
                          <div>
                            {t("ImDrehgestell")}:{" "}
                            {row.value.positionImDrehgestell
                              ? row.value.positionImDrehgestell
                              : null}{" "}
                          </div>
                          <div>
                            {t("ImRadsatz")}:{" "}
                            {row.value.positionImRadsatz
                              ? row.value.positionImRadsatz
                              : null}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }

                if (row.name === "Mess-Location") {
                  if (row.value !== undefined && row.value !== null) {
                    return (
                      <>
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {t(row.name)}
                          </TableCell>

                          <TableCell
                            style={{ width: 160, fontSize: "small" }}
                            align="right"
                          >
                            <div>
                              lat. {row.value.messLocation.split(",")[0]}
                            </div>
                            <div>
                              long. {row.value.messLocation.split(",")[1]}
                            </div>
                            <div>
                              <a
                                href={`https://www.google.com/maps?q=${
                                  row.value.messLocation.split(",")[0]
                                },${row.value.messLocation.split(",")[1]}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {t("linkGoogleMaps")}
                              </a>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  }
                }
                if (row.name === "SensorDaten") {
                  if (
                    row.value !== null &&
                    (row.value["dynamicCoefficient.1L"] !== undefined ||
                      row.value["dynamicCoefficient.1R"] !== undefined ||
                      row.value["dynamicCoefficient.2L"] !== undefined ||
                      row.value["dynamicCoefficient.2R"] !== undefined) &&
                    (row.value["dynamicCoefficient.1L"] !== null ||
                      row.value["dynamicCoefficient.1R"] !== null ||
                      row.value["dynamicCoefficient.2L"] !== null ||
                      row.value["dynamicCoefficient.2R"] !== null)
                  ) {
                    return (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>

                        <TableCell
                          style={{ width: 160, fontSize: "small" }}
                          align="right"
                        >
                          <div>{t("dynamicCoefficients")}</div>
                          <div>
                            {"1L: "}
                            {row.value["dynamicCoefficient.1L"]}
                          </div>
                          <div>
                            {"1R: "}
                            {row.value["dynamicCoefficient.1R"]}
                          </div>
                          <div>
                            {"2L: "}
                            {row.value["dynamicCoefficient.2L"]}
                          </div>
                          <div>
                            {"2R: "}
                            {row.value["dynamicCoefficient.2R"]}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {t(row.name)}
                        </TableCell>
                        <TableCell
                          style={{ width: 160 }}
                          align="right"
                        ></TableCell>
                      </TableRow>
                    );
                  }
                }
                if (row.name === "WagonNummer") {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                background: "#003060",
                                color: "#ffff",
                              }
                            : null
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>

                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        <a
                          href={`https://${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}@${process.env.REACT_APP_DISCOVERY_HOST}/train-info/${row.value?.slice(-12)}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.value}
                        </a>
                      </TableCell>
                    </TableRow>
                  );
                }
                if (row.name === "KmLaufleistung") {
                  return null;
                } else {
                  return (
                    <TableRow key={row.name}>
                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                background: "#003060",
                                color: "#ffff",
                              }
                            : null
                        }
                        component="th"
                        scope="row"
                      >
                        {t(row.name)}
                      </TableCell>

                      <TableCell
                        style={
                          row.value === pairValueColor && pairValueColor !== ""
                            ? {
                                width: 160,
                                background: "#003060",
                                color: "#ffff",
                              }
                            : { width: 160 }
                        }
                        align="right"
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </Item>
  );
}
